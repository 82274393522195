import { IoTrashBinOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import {
  Divider, Button, Alert, List, Typography, Input, Row, Col, Select, Space, Flex,
} from 'antd';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './cart.module.css';
import QtyChangerCart from '../QtyChangerCart';
import { addToCart, removeFromCart } from '../../actions/cart';
import { axiosClient } from '../../apiClient';

const { Text, Link, Title } = Typography;

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartProducts = useSelector((state) => state.cart.products);
  const productsTotal = useSelector((state) => state.cart.productsTotal);
  const minOrderValue = useSelector((state) => state.cart.minOrderValue);
  const logged = useSelector((state) => state.authentication.logged);
  const [failMessage, setFailMessage] = useState(false);
  const [editPrice, setEditPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(null);
  const [discount, setDiscount] = useState(false);
  const myRef = useRef();
  const syncData = useSelector((state) => state.sync);
  const orderRebuy = useSelector((state) => state.orderRebuy);
  const cart = useSelector((state) => state.cart);
  const sellerData = useSelector((state) => state.sellerData);

  useEffect(() => {
    if (myRef && myRef.current) {
      const { input } = myRef.current;
      input.focus();
    }
  });

  function getProductPrice(product) {
    return product.price_modified ? product.price_modified : product.product_price_sale ? product.product_price_sale : product.product_price;
  }

  function dealWithSubmit(product) {
    setEditPrice(null);
    const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price;
    parseFloat(newPrice) === actualPrice ? dispatch({ type: 'REMOVE_MODIFY_PRICE', product }) : dispatch({ type: 'MODIFY_PRICE', product, price_modified: newPrice });
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
  }

  useEffect(() => {
    if (productsTotal == 0) {
      setFailMessage('Adicione produtos no carrinho para finalizar o pedido');
    } else if (minOrderValue > productsTotal) {
      setFailMessage(
        `Adicione mais R$${parseFloat(minOrderValue - productsTotal).toFixed(
          2,
        )} no carrinho para atingir o pedido mínimo de R$${parseFloat(
          minOrderValue,
        ).toFixed(2)}`,
        5,
      );
    } else {
      setFailMessage(false);
    }
  }, [productsTotal]);

  function CartRow(props) {
    const { product } = props;

    let imgSrc = null;

    if (syncData.onlineStatus) {
      if (props.product.product_image && props.product.product_image[0]) {
        imgSrc = props.product.product_image[0].thumbnail;
      }
    } else if (syncData.onlineStatus === false && syncData.syncStatus === 'synced') {
      imgSrc = localStorage.getItem('products' + `_${props.product.id}`);
    }

    return (
      <List.Item>
        <div
          key={`card${product.product_sku}`}
          className={styles['card-item']}
        >
          <div className={styles['img-container']}>
            <img
              className={styles['product-img']}
              src={imgSrc || 'fallback.png'}
              alt=""
            />
          </div>
          <div className={styles.product_info}>
            <div className={styles.product_name}>
              {product.product_name}
            </div>
            <div className={styles['product-calc']}>
              <div className={styles['product-total']}>
                <span className={styles['product-total-text']}>
                  {((logged === 'seller' || logged === 'sellerUser') && editPrice == product.id)
                    ? (
                      <Input.Group compact>
                        <Input
                          onBlur={(e) => { setEditPrice(null); }}
                          size="small"
                          prefix="R$"
                          value={newPrice}
                          style={{ width: '60%' }}
                          onChange={(e) => {
                            const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                            setNewPrice(val.toFixed(2));
                          }}
                          ref={myRef}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              dealWithSubmit(product);
                            }
                          }}
                        />
                        <Button onMouseDown={() => dealWithSubmit(product)} size="small" type="primary"><CheckOutlined /></Button>
                      </Input.Group>
                    )
                    : (
                      <div style={{ lineHeight: '110%' }}>
                        {'price_modified' in product
                          ? (
                            <>
                              <Text delete>
                                R$
                                {product.product_price_sale ? product.product_price_sale : product.product_price}
                              </Text>
                              <br />
                              <Link onClick={() => { setNewPrice(product.price_modified); setEditPrice(product.id); }}>{`R$${product.price_modified} `}</Link>
                            </>
                          )
                          : (logged === 'seller' || logged === 'sellerUser')
                            ? (
                              <Link onClick={() => { const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price; setNewPrice(actualPrice.toFixed(2)); setEditPrice(product.id); }}>
                                R$
                                {' '}
                                {parseFloat(getProductPrice(product)).toFixed(2)}
                                {' '}
                              </Link>
                            )
                            : (
                              <Text>
                                {' '}
                                R$
                                {parseFloat(getProductPrice(product)).toFixed(2)}
                                {' '}
                              </Text>
                            )}
                        {' '}
                        x
                        {product.quantity}
                        {' '}
                        = R$
                        {parseFloat(product.quantity * getProductPrice(product)).toFixed(2)}
                      </div>
                    )}
                </span>
              </div>
              <QtyChangerCart
                func={addToCart}
                product={product}
                quantity={product.quantity}
              />
            </div>
          </div>
          <IoTrashBinOutline
            onClick={() => {
              dispatch(removeFromCart(product));
              dispatch({ type: 'CART_UPDATED', updated: true });
              dispatch({ type: 'CART_RETURNED', returned: false });
            }}
            className={styles['trash-icon']}
          />
        </div>
      </List.Item>
    );
  }

  return (
    <div className={styles['order-summary']}>
      <div className={styles['summary-card']}>
        <div className={styles['product-list']}>
          <List
            itemLayout="vertical"
            dataSource={cartProducts}
            renderItem={(product) => (
              <CartRow product={product} />
            )}
          />
        </div>
        <Divider />
        <Space direction="vertical">
          <Flex justify="space-between">
            <Title style={{ margin: 0, padding: 0 }} level={4}>Total</Title>
            <Title style={{ margin: 0, padding: 0 }} level={4}>
              R$
              {parseFloat(productsTotal).toFixed(2)}
            </Title>
          </Flex>
          {/* <Flex justify="flex-end">
            {discount ? <Input.Group style={{ display: "flex", justifyContent: "end" }} compact>
              <Select style={{ width: "110px" }} size="small" defaultValue="Desconto" options={[
                {
                  value: 'discount',
                  label: 'Desconto',
                },
                {
                  value: 'add',
                  label: 'Acréscimo',
                },
              ]} />
              <Input
                size="small"
                style={{
                  width: '80px',
                }}
                suffix="%"

              // defaultValue="0"
              />
              <Button size="small" type="primary" onClick={() => setDiscount(false)}><CheckOutlined /></Button>
            </Input.Group> : <Link onClick={() => setDiscount(true)}>Desconto</Link>}
          </Flex> */}
          <Space style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }} direction="vertical">
            {failMessage && <Alert message={failMessage} type="info" />}
            <Button
              size="large"
              className={styles['finish-order']}
              type="primary"
              disabled={!!failMessage}
              onClick={() => {
                if (!orderRebuy.orderRebuyId) {
                  failMessage == false && dispatch({ type: 'GO_CHECKOUT' });
                } else {
                  axiosClient
                    .post(
                      '/cart/order/update',
                      { orderId: orderRebuy.orderRebuyId, cart },
                      { withCredentials: true },
                    )
                    .then((response) => {
                      if (response.data.status === 'Success') {
                        dispatch({ type: 'RESET_REBUY' });
                        navigate(`/${sellerData.username}/checkout/${orderRebuy.orderRebuyId}`);
                      }
                    });
                }
              }}
            >
              Finalizar Pedido
            </Button>
          </Space>
        </Space>
      </div>

      <div />
    </div>
  );
}
