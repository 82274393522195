import {
  Skeleton,
  Card,
  Drawer,
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
} from 'antd';
import { KeyOutlined, EllipsisOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.css';
import { axiosClient } from '../../apiClient';

const { Meta } = Card;

export default function IntegrationsPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingTinyKeys, setLoadingTinyKeys] = useState(false);
  const [loadingNewKeys, setLoadingNewKeys] = useState(false);
  const [loadingDeleteKeys, setLoadingDeleteKeys] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tinyHasKeys, setTinyHasKeys] = useState(false);

  useEffect(() => {
    axiosClient
      .get('/integrations/tiny/get-keys', { withCredentials: true })
      .then((response) => {
        if (response.data.data.integration) {
          setTinyHasKeys(true);
          form.setFieldsValue({
            consumer_key: response.data.data.integration.consumer_key,
            consumer_secret: response.data.data.integration.consumer_secret,
            description: response.data.data.description,
          });
        } else {
          setTinyHasKeys(false);
        }
        setLoading(false);
      });
  }, []);

  const updateKeys = () => {
    setLoadingNewKeys(true);
    axiosClient
      .get('/integrations/tiny/generate-keys', { withCredentials: true })
      .then((response) => {
        if (response.data.data.integration) {
          setTinyHasKeys(true);
          form.setFieldsValue({
            consumer_key: response.data.data.integration.consumer_key,
            consumer_secret: response.data.data.integration.consumer_secret,
            description: response.data.data.description,
          });
          message.success('Novas chaves geradas com sucesso', 4);
        } else {
          setTinyHasKeys(false);
          message.error('Erro ao gerar chaves', 4);
        }
        setLoadingNewKeys(false);
      });
  };

  const deleteKeys = () => {
    setLoadingDeleteKeys(true);
    axiosClient
      .get('/integrations/tiny/delete-keys', { withCredentials: true })
      .then((response) => {
        if (response.data.status == 'Success') {
          setTinyHasKeys(false);
          form.setFieldsValue({
            consumer_key: '',
            consumer_secret: '',
          });
          message.success('Chaves deletadas com sucesso', 4);
        } else {
          message.error('Erro ao deletar chaves', 4);
        }
        setLoadingDeleteKeys(false);
      });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Card
          style={{ width: 300 }}
          cover={(
            <img
              alt="tiny"
              style={{
                width: '60%',
                margin: 'auto',
                marginTop: '10px',
              }}
              src="https://storage.googleapis.com/nupedido-public-bucket/static/images/integrations/tiny.png"
            />
        )}
          actions={[
            <Button
              onClick={() => {
                showDrawer();
              }}
            >
              Gerenciar
            </Button>,
          ]}
        >
          <Meta
            title="Tiny ERP"
            description="Integrar seu catálogo Nupedido com o gestor empresarial Tiny ERP."
          />
        </Card>
      )}
      <Drawer
        title="Gerenciamento de integração - Tiny ERP"
        placement="bottom"
        width={500}
        height={window.innerWidth > 575 ? '360px' : '480px'}
        onClose={onClose}
        open={drawerVisible}
      >
        {loadingTinyKeys ? (
          <Skeleton active />
        ) : (
          <Form layout="vertical" hideRequiredMark form={form}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="url" label="URL do site">
                  <Input
                    readOnly
                    defaultValue="https://api.nupedido.com.br/"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item name="consumer_key" label="Consumer Key">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="consumer_secret" label="Consumer Secret">
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <div rootStyle={{ marginTop: 30 }}>
                <Row justify="center" gutter={[30, 12]}>
                  {tinyHasKeys && (
                  <Col xs={18} sm={8}>
                    <Button
                      block
                      onClick={() => {
                        deleteKeys();
                      }}
                      loading={loadingDeleteKeys}
                    >
                      Excluir
                    </Button>
                  </Col>
                  )}
                  <Col xs={18} sm={8}>
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        updateKeys();
                      }}
                      loading={loadingNewKeys}
                    >
                      {tinyHasKeys ? 'Gerar novamente' : 'Gerar Chaves'}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
}
