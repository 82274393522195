import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  message, Typography, Dropdown, Menu, Space, Tag,
} from 'antd';
import {
  DownOutlined, KeyOutlined, LogoutOutlined, SyncOutlined,
} from '@ant-design/icons';
import logout from '../../actions/logout';
import styles from './navbar.module.css';
import BuyerChangePass from '../BuyerChangePass';
import { axiosClient } from '../../apiClient';

const { Link } = Typography;

const splits = window.location.hostname.split('.');
const domainName = splits.slice(Math.max(splits.length - 3, 1)).join('.');

function Navbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [failMessage, setFailMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const sellerData = useSelector((state) => state.sellerData);
  const minOrderValue = useSelector((state) => state.cart.minOrderValue);
  const cartProducts = useSelector((state) => state.cart.products);
  const cart = useSelector((state) => state.cart);
  const logged = useSelector((state) => state.authentication.logged);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const orderRebuy = useSelector((state) => state.orderRebuy);

  useEffect(() => {
    if (cartProducts.length == 0) {
      setFailMessage('Adicione produtos no carrinho para finalizar o pedido');
    } else if (minOrderValue > total) {
      setFailMessage(
        `Adicione mais R$${parseFloat(minOrderValue - total).toFixed(
          2,
        )} no carrinho para atingir o pedido mínimo de R$${parseFloat(
          minOrderValue,
        ).toFixed(2)}`,
        5,
      );
    } else {
      setFailMessage(false);
    }
  }, [cartProducts]);

  function amount(item) {
    return item.product_price_sale
      ? parseFloat(item.product_price_sale * item.quantity).toFixed(2)
      : parseFloat(item.product_price * item.quantity).toFixed(2);
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  let total = 0;
  if (cartProducts.length) {
    total = cartProducts.map(amount).reduce(sum);
  }

  const menu = (
    <Menu>
      <Menu.Item
        icon={<KeyOutlined />}
        onClick={() => {
          setDrawerVisible(true);
        }}
        key="2"
      >
        Trocar Senha
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<LogoutOutlined />}
        onClick={() => {
          logout(sellerData.domain ? '/' : sellerData.username);
        }}
      >
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <header
        style={{ top: logged === 'seller' || logged === 'sellerUser' ? 50 : 0 }}
        className={styles.header}
      >
        <div className={styles['div-navbar']}>
          {sellerData.logotipoUrl && (
            <div className={styles['img-container']}>
              <img
                alt="Logotipo"
                src={sellerData.logotipoUrl}
                onClick={() => {
                  sellerData.domain
                    ? navigate('/')
                    : navigate('/admin');
                }}
                className={styles['img-logo']}
              />
            </div>
          )}
        </div>
        <input
          className={styles['menu-btn']}
          onClick={() => setChecked(!checked)}
          checked={checked}
          type="checkbox"
          id="menu-btn"
        />
        <label className={styles['menu-icon']} htmlFor="menu-btn">
          <span className={styles.navicon} />
        </label>
        <ul className={styles.menu}>
          <li
            className={styles['li-class']}
            style={{
              display: logged === 'buyer' ? 'none' : 'block',
            }}
          >
            <Link
              onClick={() => navigate('/admin')}
            >
              Painel Administrativo
            </Link>
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'block' : 'none',
            }}
          >
            <Link
              onClick={() => {
                sellerData.domain
                  && domainName === sellerData.domain
                  && logged === 'buyer'
                  ? navigate('/')
                  : navigate(`/${sellerData.username}`);
              }}
            >
              Retornar aos Produtos
            </Link>
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'none' : 'block',
            }}
          >
            <Link
              onClick={() => {
                props.handleToggle();
                setChecked(false);
              }}
            >
              Histórico de Pedidos
            </Link>
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'none' : 'block',
            }}
          >
            <Link
              onClick={() => {
                if (!orderRebuy.orderRebuyId) {
                  failMessage
                    ? message.warning(failMessage)
                    : dispatch({ type: 'GO_CHECKOUT' });
                } else {
                  axiosClient
                    .post(
                      '/cart/order/update',
                      { orderId: orderRebuy.orderRebuyId, cart },
                      { withCredentials: true },
                    )
                    .then((response) => {
                      if (response.data.status === 'Success') {
                        dispatch({ type: 'RESET_REBUY' });
                        navigate(`/${sellerData.username}/checkout/${orderRebuy.orderRebuyId}`);
                      }
                    });
                }
              }}
            >
              Finalizar Pedido
            </Link>
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'none' : 'block',
            }}
          >
            <Dropdown
              overlay={menu}
              trigger={['click']}
              className="ant-dropdown-link"
            >
              <Link onClick={(e) => e.preventDefault()}>
                <Space>
                  Opções
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          </li>
        </ul>
      </header>
      {drawerVisible === true && (
        <BuyerChangePass
          drawer={drawerVisible}
          drawerSetter={setDrawerVisible}
        />
      )}
    </>
  );
}

export default Navbar;
