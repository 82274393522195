function initialState() {
  return {
    logotipoUrl: null,
    fantasyName: null,
    domain: null,
    subdomain: null,
    username: null,
    pathName: null,
    primaryColor: null,
    permissions: false,
  };
}

export function sellerData(state = initialState(), action) {
  switch (action.type) {
    case 'SET_SELLER':
      return {
        ...state,
        logotipoUrl: action.url,
        fantasyName: action.nome_fantasia,
        domain: action.dominio,
        username: action.username,
        pathName: action.pathName,
        primaryColor: action.primary_color,
        permissions: action.permissions,
      };

    default:
      return state;
  }
}
