const INITIAL_STATE = {
  products: [],
  minOrderValue: 0,
  productsTotal: 0,
  shippingValue: 0,
  discountValue: 0,
  cartTotal: 0,
  retrieved: false, // Se retornou pela primeira vez
  updated: false, // Se foi modificado e ainda não retornou
  returned: false, // Se está atualizado
  goToCheckout: false,
};

export function cart(state = INITIAL_STATE, action) {
  const findIndex = () => state.products.findIndex(
    (obj) => obj.product_sku === action.product.product_sku,
  );

  switch (action.type) {
    case 'SET_CART':
      return {
        ...state,
        ...('updated' in action && { updated: action.updated }),
        ...('returned' in action && { returned: action.returned }),
        ...('minOrderValue' in action && { minOrderValue: action.minOrderValue }),
        ...('productsTotal' in action && { productsTotal: action.productsTotal }),
        ...('shippingValue' in action && { shippingValue: action.shippingValue }),
        ...('discountValue' in action && { discountValue: action.discountValue }),
        ...('cartTotal' in action && { cartTotal: action.cartTotal }),
      };
    case 'CART_UPDATED':
      return { ...state, updated: action.updated };
    case 'CART_RETURNED':
      return { ...state, returned: action.returned };
    case 'GO_CHECKOUT':
      return { ...state, goToCheckout: true };
    case 'SET_MIN_ORDER':
      return {
        ...state,
        minOrderValue: action.minOrderValue,
      };
    case 'SET_PRODUCTS_TOTAL':
      return {
        ...state,
        productsTotal: action.productsTotal,
      };
    case 'SET_SHIPPING_VALUE':
      return {
        ...state,
        shippingValue: action.shippingValue,
      };
    case 'SET_DISCOUNT':
      return {
        ...state,
        discountValue: action.discountValue,
      };
    case 'SET_CART_TOTAL':
      return {
        ...state,
        cartTotal: action.cartTotal,
      };
    case 'CART_RETRIEVED':
      return {
        ...state,
        retrieved: true,
      };
    case 'ADD_CART':
      const addIdx = state.products.findIndex(
        (obj) => obj.product_sku === action.product.product_sku,
      );
      if (findIndex() !== -1) {
        return {
          ...state,
          products: [
            ...state.products.slice(0, addIdx),
            {
              ...state.products[addIdx],
              quantity: state.products[addIdx].quantity + action.variation,
            },
            ...state.products.slice(addIdx + 1),
          ],
        };
      }
      return {
        ...state,
        products: [
          ...state.products,
          { ...action.product, quantity: action.variation },
        ],
      };

    case 'MODIFY_PRICE':
      const addIdx2 = state.products.findIndex(
        (obj) => obj.product_sku === action.product.product_sku,
      );
      return {
        ...state,
        products: [
          ...state.products.slice(0, addIdx2),
          {
            ...state.products[addIdx2],
            price_modified: action.price_modified,
          },
          ...state.products.slice(addIdx2 + 1),
        ],
      };
    case 'REMOVE_MODIFY_PRICE':
      const addIdx3 = state.products.findIndex(
        (obj) => obj.product_sku === action.product.product_sku,
      );

      const { price_modified, ...rest } = state.products[addIdx3];

      return {
        ...state,
        products: [
          ...state.products.slice(0, addIdx3),
          {
            ...rest,
          },
          ...state.products.slice(addIdx3 + 1),
        ],
      };
    case 'REMOVE_CART':
      const removeIdx = findIndex();
      if (removeIdx !== -1) {
        return {
          ...state,
          products: [
            ...state.products.slice(0, removeIdx),
            ...state.products.slice(removeIdx + 1),
          ],
        };
      }
      return state;

    case 'RESET_CART':
      return INITIAL_STATE;
    default:
      return state;
  }
}
