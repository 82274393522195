import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { openDB } from 'idb';
import { axiosClient } from '../../apiClient';
import styles from './search.module.css';
import {
  mascaraCnpj,
  mascaraCpf,
} from '../../functions/validation';

export default function SearchCustomer(props) {
  const dispatch = useDispatch();
  const { Option, OptGroup } = Select;
  const buyerPagination = useSelector((state) => state.buyerPagination);
  const searchTermValue = useSelector(
    (state) => state.buyerSearchState.searchTerm,
  );
  const buyerList = useSelector((state) => state.buyerList);
  const syncData = useSelector((state) => state.sync);

  useEffect(() => { dispatch({ type: 'SET_BUYER_LOADING', loading: true }); }, []);

  const updateSearch = async (page, perPage, searchTerm, resetPage, syncStatus) => {
    const db = await openDB('nupedido', 1);
    const dbConfig = await db.get('config', 1);

    if (dbConfig.synced || syncStatus == 'synced') {
      async function getAllItemsFromStoreWithCursor() {
        const tx = await db.transaction('buyers', 'readonly');

        // Open a cursor on the designated object store:
        let cursor = await tx.store.openCursor();

        // Iterar sobre os dados usando o cursor
        const results = [];

        const newSearchTerm = searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]|-|\/|\./g, '');

        // Loop through the cursor
        while (cursor) {
          const { key, value } = cursor;

          const normalizedName = value && value.nome ? value.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
          const normalizedEmail = value && value.email ? value.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
          const normalizedCpf = value && value.cpf ? value.cpf.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
          const normalizedCnpj = value && value.cnpj ? value.cnpj.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
          const normalizedFantasia = value && value.nome_fantasia ? value.nome_fantasia.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
          const normalizedRazao = value && value.razao_social ? value.razao_social.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';

          // Check if the value exists and searchTerm is included in any of the fields
          if (
            value
            && ((normalizedName.includes(newSearchTerm))
              || (normalizedEmail.includes(newSearchTerm))
              || (normalizedCpf.includes(newSearchTerm))
              || (normalizedCnpj.includes(newSearchTerm))
              || (normalizedFantasia.includes(newSearchTerm))
              || (normalizedRazao.includes(newSearchTerm)))
          ) {
            // Add the matched value to the results array
            results.push(value);
          }

          // Move to the next row
          cursor = await cursor.continue();
        }

        // Calculate total pages
        const totalPages = Math.ceil(results.length / perPage);

        // Pagination
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const paginatedResults = results.slice(startIndex, endIndex);

        return {
          buyers: paginatedResults,
          totalPages,
          actualPage: page,
        };
      }

      const queryResult = await getAllItemsFromStoreWithCursor();

      dispatch({ type: 'SET_BUYER_LIST', list: queryResult.buyers });
      dispatch({ type: 'SET_BUYER_LOADING', loading: false });
      dispatch({
        type: 'SET_BUYER_PAGE',
        totalPages: queryResult.totalPages,
        actualPage: queryResult.actualPage,
        pageUpdated: true,
      });
    } else {
      const results = await axiosClient.post('/auth/search/buyer', {
        search: searchTerm,
        page: resetPage ? 1 : page,
        perPage,
      }, { withCredentials: true });
      dispatch({ type: 'SET_BUYER_LIST', list: results.data.buyers });
      dispatch({ type: 'SET_BUYER_LOADING', loading: false });
      dispatch({
        type: 'SET_BUYER_PAGE',
        totalPages: results.data.total_pages,
        actualPage: results.data.actual_page,
        pageUpdated: true,
      });
    }
  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  const handleSearchChangeSelect = async (value) => {
    dispatch({ type: 'SET_BUYER_SEARCH_TERM', searchTerm: value });
  };

  useEffect(() => {
    dispatch({ type: 'SET_BUYER_LOADING', loading: true });

    updateSearchHandler(
      buyerPagination.page,
      buyerPagination.perPage,
      searchTermValue,
      true,
      props.dontUseOffline ? false : syncData.syncStatus,
    );
  }, [searchTermValue, buyerPagination.perPage]);

  useEffect(() => {
    !buyerPagination.pageUpdated
      && updateSearchHandler(
        buyerPagination.page,
        buyerPagination.perPage,
        searchTermValue,
        false,
        props.dontUseOffline ? false : syncData.syncStatus,
      );
  }, [buyerPagination.page]);

  return (
    <Select
      showSearch
      size="large"
      onChange={props.handleSelectChange}
      autoClearSearchValue={false}
      style={{ width: '100%' }}
      placeholder="Pesquisar Cliente"
      allowClear
      onSearch={handleSearchChangeSelect}
      loading={buyerList.loading}
      filterOption={false}
      notFoundContent={buyerList.loading ? <Spin size="small" /> : null}
    >
      {buyerList.loading
        ? null
        : buyerList.list.map((option) => (option.tipo_pessoa === 'pf' ? (
          <Option key={option.id} value={option.id}>
            {`${option.cpf} - ${option.nome}`}
          </Option>
        ) : (
          <Option key={option.id} value={option.id}>
            {`${option.cnpj} - ${option.razao_social}`}
          </Option>
        )))}
    </Select>
  );
}
